import React, { useContext, useEffect, useRef } from "react";
import Home from "./Home";
import Photography from "./Photography";
import Products from "./Products";
import Contact from "./Contact";
import ReactFullpage from "@fullpage/react-fullpage";

const Slides = () => (
  <ReactFullpage.Wrapper>
    <div className="section">
      <Home />
    </div>
    <div className="section">
      <Products />
    </div>
    <div className="section">
      <Photography />
    </div>
    <div className="section">
      <Contact />
    </div>
  </ReactFullpage.Wrapper>
);

export default Slides;
