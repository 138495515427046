import React from "react";
import logo from "url:../public/main-hero.jpg";

const Home = () => {
  return (
    <>
      <div className="flex h-screen items-center">
        <div className="container mx-auto flex">
          <div className="flex flex-col md:flex-row items-center md:items-start">
            <div className="primary-hero m-4">
              <div className="border-0 border-gray-200 shadow-lg w-56 md:w-72 rounded-md overflow-hidden">
                <img src={logo} alt="" className="" />
              </div>
            </div>
            <div className="meta pl-4 pr-4">
              <h1 className="text-3xl row-span-2 self-center pt-4 pb-4 font-suez text-center md:text-left ">
                VIJAY SUTRAVE.
              </h1>
              <div className="primary-border border border-b-3 border-primary w-24 mx-auto md:mx-0"></div>
              <h4 className="mt-8 text-l md:text-xl  row-span-1 self-center pt-2 pb-2 text-center md:text-left">
                <code>Welcome to my space on the internet 👋🏼 </code>
              </h4>
              <h4 className="text-sm md:text-md  row-span-1 self-center pt-2 pb-2 text-center md:text-left">
                <code>
                  I use this small, loving space, to showcase my work,
                  creativity and thoughts. Glad you're here, please feel at
                  home!
                </code>
              </h4>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
