import React from "react";

export default function Header(props: { title: string }) {
  return (
    <div className="meta pr-4 items-center">
      <div className="text-3xl row-span-2 self-center pt-4 pb-4 font-suez text-center md:text-left">
        {props.title}.
      </div>
      <div className="primary-border border border-b-3 border-primary w-24 mx-auto md:mx-0"></div>
    </div>
  );
}
