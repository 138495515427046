import React from "react";
import logo from "url:../public/atlassian.png";
import Slider from "react-slick";
import ClayOfHope from "url:../public/clayofhope.jpeg";
import Upfive from "url:../public/upfive.png";
import TheWickBar from "url:../public/thewickbar.png";
import TripperStories from "url:../public/tripperstories.png";
import Chillify from "url:../public/chillify.png";
import ChromeFirstPaint from "url:../public/chrome-first-paint.jpeg";
import Fluffy from "url:../public/fluffy.jpeg";
import Github from "url:../public/github.png";
import EasyEstimate from "url:../public/easy-estimate-home.png";
import DrumrollAppHome from "url:../public/drumroll-app-home.png";
import Header from "../common/Header";

const settings = {
  dots: true,
  speed: 500,
  slidesToShow: window.innerWidth < 1024 ? 1 : 3,
  slidesToScroll: 1,
  centerMode: true,
  arrows: true,
  initialSlide: 1,
};
console.log({ settings });

const imgs = [
  {
    img: Upfive,
    text: "Built a platform, using AI to aggreate all work data across third parties in one place",
    href: "http://upfive.ai",
  },
  {
    img: EasyEstimate,
    text: "Jira Marketplace App to help master your team's project delivery and estimates",
    href: "https://easyestimate.app/",
  },
  {
    img: DrumrollAppHome,
    text: "Drumroll helps your team to quickly find answers to previously answered questions, with a Reddit and StackOverlow-like interface right in your Slack.",
    href: "https://thedrumrollapp.com/",
  },
  {
    img: ClayOfHope,
    text: "Online platform to share happy stories, news and positivity! Follow @clayofhope on Instagram!",
    href: "http://clayofhope.com",
  },
  {
    img: TheWickBar,
    text: "E-commerce platform to customise and sell candles built with React, Tailwind, Node, Stripe.",
    href: "http://wickbar.com.au",
  },
  {
    img: TripperStories,
    text: "Share you travel pictures, stories, book hotels and more!",
    href: "http://tripperstories.com",
  },
  {
    img: Chillify,
    text: "Social network built in 2012 for students to share notes, events, etc!",
  },
  {
    img: ChromeFirstPaint,
    text: "Chrome extension to show how fast your webpage performance statistics!",
    href: "https://chrome.google.com/webstore/detail/first-paint-page-load-tim/bjkmldgdbbehjahimccnckggoofdommo",
  },
  {
    img: Fluffy,
    text: "Simple, clean and easy note taking Chrome extension",
    href: "https://chrome.google.com/webstore/detail/fluffy/cldodkkbpbaglfighijecbpncojpjnpn",
  },
  {
    img: Github,
    text: "View all on Github",
    href: "https://github.com/vijaysutrave",
  },
];

const Home = () => {
  let slider: Slider;

  const prevClick = () => {
    slider.slickPrev();
  };

  const nextclick = () => {
    slider.slickNext();
  };

  return (
    <>
      <div className="flex h-screen">
        <div className="mt-16 container mx-auto ">
          <div className="flex flex-col md:flex-row items-center md:items-start">
            <div className="meta pl-4 ">
              <Header title="WORK" />
              <div className="work-timeline mt-4 mb-4">
                <div className="start-year text-xs md:text-sm text-gray-500">
                  &bull; Present
                </div>
                <div className="flex  items-center">
                  <div className="timeline">
                    <div className="left-border h-24 border-l-2 opacity-10 border-gray-100"></div>
                  </div>

                  <div className="atlassian-logo w-12 m-4">
                    <img src={logo} alt="" />
                  </div>
                  <div className="job-desc">
                    <div className="job-title text-sm md:text-base">
                      Engineering Manager, Atlassian
                    </div>
                    <div className="job-desc">
                      <ul className="list-disc list-outside text-xs md:text-base leading-6 mt-2 ">
                        <li className="ml-6 mr-6">
                          Shipped features in the Rich Text Editor and built
                          Smart Links for all Atlassian products for millions of
                          users
                        </li>
                        <li className="ml-6 mr-6">
                          Led multiple projects to delivery
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="end-year text-xs md:text-sm text-gray-500">
                  &bull; Feb, 2018
                </div>
                <div className="text-gray-500">
                  . <br />
                </div>
                <div className="full-work-profile text-gray-500 md:text-base text-xs">
                  View full profile on &nbsp;
                  <a
                    className="underline"
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.linkedin.com/in/vijaysutrave/"
                  >
                    Linkedin
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="products-carousel flex flex-row align-center">
            <div
              className="m-2 md:m-12 text-2xl cursor-pointer"
              onClick={prevClick}
            >
              👈🏽
            </div>
            <div className="main-slider">
              <Slider ref={(c: Slider) => (slider = c)} {...settings}>
                {imgs.map((el, index) => (
                  <div className="overflow-hidden rounded-s" key={index}>
                    <div className="m-1 md:m-8 overflow-hidden">
                      <div className="proj-img shadow-lg">
                        <a target="_blank" rel="noreferrer" href={el.href}>
                          <img className="cursor-pointer" src={el.img} />
                        </a>
                      </div>
                      <div className="proj-desc text-center text-italics p-4 text-xs md:text-lg">
                        {el.text}
                      </div>
                    </div>
                  </div>
                ))}
              </Slider>
            </div>

            <div
              className="m-2 md:m-8 text-2xl cursor-pointer"
              onClick={nextclick}
            >
              👉🏽
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
