import React, { useEffect, useState } from "react";
import logo from "url:../public/main-hero.jpg";
import Header from "../common/Header";

const Photography = () => {
  const [photos, setPhotos] = useState([]);
  useEffect(() => {
    (async () => {
      const photos = await (await fetch("//pics.vijaysutrave.com")).json();
      setPhotos(photos.slice(0, 5));
    })();
    return () => {};
  }, []);
  return (
    <>
      <div className="flex h-screen">
        <div className="mt-16 container mx-auto ">
          <div className="flex flex-col md:flex-row items-center md:items-start">
            <div className="meta pl-4">
              <Header title="PHOTOGRAPHY" />
              <div className="full-work-profile text-gray-500 text-s mt-4">
                <a
                  className="flex items-center"
                  target="_blank"
                  rel="noreferrer"
                  href="https://instagram.com/vijaysutrave/"
                >
                  <i className="fab fa-instagram text-2xl"></i>
                  <span>&nbsp; @vijaysutrave</span>
                </a>
              </div>
              <div className="photos-container mt-4 mb-4">
                <div className="grid grid-cols-3 md:grid-cols-5">
                  {photos.map((photo: any) => (
                    <a
                      href={photo.links.html}
                      target="_blank"
                      rel="noreferrer"
                      key={photo.id}
                      className="	 bg-white rounded text-grey-darkest no-underline m-4 overflow-hidden"
                    >
                      <img
                        className="block rounded-b object-cover h-full w-full shadow-lg overflow-hidden"
                        src={photo.urls.regular}
                        alt={photo.description}
                      />
                    </a>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Photography;
