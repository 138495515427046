import React from "react";
import Header from "../common/Header";

const Contact = () => {
  return (
    <>
      <div className="flex h-screen">
        <div className="mt-16 container mx-auto ">
          <div className="meta pl-4 pr-4">
            <Header title="CONTACT" />
            <div className="contact-items m-4 flex flex-col items-center text-gray-400 md:items-start">
              <div className="email pt-4 pb-4 flex flex-col items-center md:items-start">
                <div className="email-text  my-4">
                  <i>Write a few words</i>
                </div>
                <a href="mailto:vijaysutrave01@gmail.com">
                  <i className="far fa-envelope-open text-5xl"></i>
                </a>
              </div>
              <div className="or-text text-2xl my-6">
                <i>OR</i>
              </div>
              <div className="social pt-4 pb-4 flex flex-col items-center md:items-start">
                <div className="email-text  my-4">
                  <i>Connect via</i>
                </div>
                <div className="social-icons">
                  <a
                    className="mx-4"
                    href="https://www.linkedin.com/in/vijaysutrave/"
                    target="blank"
                    rel="noreferrer"
                  >
                    <i className="fab fa-linkedin text-5xl"></i>
                  </a>
                  <a
                    className="mx-4"
                    href="https://instagram.com/vijaysutrave"
                    target="blank"
                    rel="noreferrer"
                  >
                    <i className="fab fa-instagram text-5xl"></i>
                  </a>
                  <a
                    className="mx-4"
                    href="https://twitter.com/vijaysutrave01"
                    target="blank"
                    rel="noreferrer"
                  >
                    <i className="fab fa-twitter text-5xl"></i>
                  </a>
                  <a
                    className="mx-4"
                    href="https://github.com/vijaysutrave"
                    target="blank"
                    rel="noreferrer"
                  >
                    <i className="fab fa-github text-5xl"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Contact;
