import React, { useEffect, useState } from "react";
import Nav from "./Nav";
import Slides from "./Slides";
import ReactFullpage from "@fullpage/react-fullpage";

const App = () => {
  return (
    <div className="app-container font-crimson">
      <Nav />
      <ReactFullpage
        licenseKey="375D6D68-DE1349FB-B07CADCB-3E933E60"
        scrollingSpeed={1000} /* Options here */
        render={() => <Slides />}
      />
    </div>
  );
};

export default App;
