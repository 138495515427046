import React from "react";

const Nav = () => {
  return (
    <div className="main-nav fixed w-full right-0 h-16 bg-white shadow-sm z-50">
      <div className="flex h-full float-right">
        <div className="flex items-center text-right">
          <div
            className="text-xs md:text-base w-20 md:w-32 text-center cursor-pointer"
            onClick={() => (window as any).fullpage_api.moveTo(2)}
          >
            Work
          </div>
          <div
            className="text-xs md:text-base w-20 md:w-32 text-center cursor-pointer"
            onClick={() => (window as any).fullpage_api.moveTo(3)}
          >
            Photography
          </div>
          <div
            className="text-xs md:text-base w-20 md:w-32 text-center cursor-pointer"
            onClick={() => (window as any).fullpage_api.moveTo(4)}
          >
            Contact
          </div>
          <div className="text-xs md:text-base w-20 md:w-32 text-center cursor-pointer">
            <a
              href="http://shop.vijaysutrave.com"
              target="_blank"
              rel="noreferrer"
            >
              Shop
            </a>
          </div>
          <div className="text-xs md:text-base w-20 md:w-32 text-center cursor-pointer">
            <a
              href="http://blog.vijaysutrave.com"
              target="_blank"
              rel="noreferrer"
            >
              Blog
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Nav;
